import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';

import {
  Container,
  HeroContainer,
  Section,
  SectionImage,
  SectionVideo,
} from 'components/Container';
import { CtaHoldingsNotice } from 'components/Notice';
import { Cards } from 'components/Cards';
import { TrustPilot } from 'components/TrustPilot';
import { PricingCards } from 'components/PricingCards';
import { ImageBlock } from 'components/ImageBlock';
import {
  portfolioTrackerCards,
  uploadHoldingsCards,
  usePortfolioTrackerCards,
} from 'page-data/portfolio-tracker';

import { useContentfulLocale } from 'hooks/useContentfulLocale';
import { useContentfulPage } from 'hooks/useContentfulPage';

import { ContentBlock } from 'components/ContentBlock';
import { useGatsbyImage } from 'hooks/useGatsbyImage';

import type { FC } from 'react';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';

export const PortfolioTracker: FC<LocalizedPageProps> = () => {
  const {
    priceIncludesTax,
    translations: { builtForInvestors, investorsJustLikeYou, supportedCurrencies },
  } = useContentfulLocale();

  const {
    translations: { pricingNote, sharesightsAwardWinningPerformance },
    cards,
    sections,
  } = useContentfulPage();

  const { trustedCards } = usePortfolioTrackerCards();

  const allYourDividendsImg = useGatsbyImage({
    name: 'shared-page-assets/localised/taxable-income-report',
    alt: 'Taxable Income Report',
  });

  return (
    <Layout>
      <Seo />

      <HeroContainer>
        <Section>
          <h1>All your investments in one place, not all over the place</h1>
          <p>
            {sharesightsAwardWinningPerformance} You can also track cash and over{' '}
            {supportedCurrencies} currencies, as well as unlisted investments such as fixed interest
            and investment properties. And it’s all wrapped up in an easy-to-use online portfolio
            tracker.
          </p>
        </Section>

        <SectionVideo videoUrl="https://www.youtube.com/watch?v=IIgxxIfHOis" />
      </HeroContainer>
      <CtaHoldingsNotice padding={10} />
      <Cards
        padding={15}
        header="Swap your spreadsheet for Sharesight"
        subHeader="Tracking your portfolio used to be a hassle. Sharesight makes it easy."
        cards={portfolioTrackerCards}
      />
      <Container padding={15}>
        <Section center>
          <h2>Powerful performance insights</h2>
          <p>
            Sharesight includes everything you need to manage your investment portfolio, and can be
            tailored to suit your needs.
          </p>
          <br />
          <br />
        </Section>

        <SectionImage
          boxShadow={false}
          image={useGatsbyImage({
            name: 'shared-page-assets/portfolio-overview',
            alt: "User Interface of Sharesight's portfolio and dividend tracker with graphic highlights over the capital gain, dividends and currency gain return metrics.",
          })}
        />
      </Container>
      <CtaHoldingsNotice padding={10} />

      <Cards
        padding={15}
        header={builtForInvestors}
        subHeader={investorsJustLikeYou}
        cards={cards}
      />

      {!!sections && sections.length > 0 && (
        <ContentBlock
          header={sections[0]?.header}
          textContent={sections[0]?.textContent}
          image={allYourDividendsImg}
          padding={15}
        />
      )}

      <Cards
        padding={15}
        header="Upload your holdings, and we'll take it from there"
        cards={uploadHoldingsCards}
      />

      <CtaHoldingsNotice padding={10} />

      <TrustPilot padding={15} />

      <Cards padding={15} header="Trusted by investors just like you" cards={trustedCards} />
      <Container manageLayout={false} padding={15}>
        <Section center>
          <h2>Plans & Pricing</h2>
          <p>
            Start by tracking up to 10 holdings for <strong>free</strong>! Then upgrade to a premium
            plan at any time to track additional holdings or portfolios, and unlock advanced
            features.{' '}
            {priceIncludesTax && (
              <strong>And as a bonus, your Sharesight subscription may be tax deductible. *</strong>
            )}
          </p>
          <br />
        </Section>

        <PricingCards />

        {pricingNote && <p>{pricingNote}</p>}
      </Container>

      <ImageBlock variation="awards" />
    </Layout>
  );
};

export default PortfolioTracker;
